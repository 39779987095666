import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { environment } from '../utils/Environment'
import {
    BecomeLessorFormData,
    BecomeLesseeFormData,
    LogingFormData,
    ModifyLesseeFormData,
    RegisterResponse,
    UserUpdateResponse,
    ModifyPasswordFormData,
    ModifyNotificationsFormData
} from '../utils/Interface'
import { RootState } from '../store/GlobalStore'

const UserAPI = createApi({
    reducerPath: 'UserAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: environment.api_url as string,
        prepareHeaders: (headers, { getState }) => {
            headers.set('Content-Type', 'application/json')
            const token = (getState() as RootState).Auth.jwt
            if (token) {
                headers.set('Authorization', `Bearer ${token}`)
            }
            return headers
        }
    }),
    endpoints: (build) => ({
        login: build.mutation<RegisterResponse, LogingFormData>({
            query: (data: LogingFormData) => ({
                url: 'auth/local',
                method: 'POST',
                body: data
            })
        }),
        update: build.mutation<UserUpdateResponse, ModifyLesseeFormData>({
            query: (data: ModifyLesseeFormData) => ({
                url: 'user/update',
                method: 'POST',
                body: data
            })
        }),
        changePassword: build.mutation<
            UserUpdateResponse,
            ModifyPasswordFormData
        >({
            query: (data: ModifyPasswordFormData) => ({
                url: 'user/change-password',
                method: 'POST',
                body: data
            })
        }),
        updateNotifications: build.mutation<
            UserUpdateResponse,
            ModifyNotificationsFormData
        >({
            query: (data: ModifyNotificationsFormData) => ({
                url: 'user/update/notifications',
                method: 'POST',
                body: data
            })
        }),
        sendLessorDemand: build.mutation<
            RegisterResponse,
            BecomeLessorFormData
        >({
            query: (data: BecomeLessorFormData) => ({
                url: 'lessor-demand/create',
                method: 'POST',
                body: data
            })
        }),
        register: build.mutation<
            RegisterResponse,
            { data: BecomeLesseeFormData; isLessor: boolean }
        >({
            query: ({
                data,
                isLessor
            }: {
                data: BecomeLesseeFormData
                isLessor: boolean
            }) => {
                const parsedData = {
                    username: data.firstname,
                    email: data.email,
                    provider: 'local',
                    password: data.password,
                    confirmed: false,
                    blocked: isLessor,
                    firstname: data.firstname,
                    lastname: data.lastname,
                    phone: data.phone,
                    birthdate: data.birthdate,
                    isagreementaccepted: data.isagreementaccepted,
                    isnewsletteraccepted: data.isnewsletteraccepted,
                    role: isLessor ? 4 : 3
                }
                return {
                    url: 'auth/local/register',
                    method: 'POST',
                    body: parsedData
                }
            }
        })
    })
})

export default UserAPI
export const {
    useLoginMutation,
    useSendLessorDemandMutation,
    useRegisterMutation,
    useUpdateMutation,
    useChangePasswordMutation,
    useUpdateNotificationsMutation
} = UserAPI

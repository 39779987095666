// ToggleSwitch.js
import styles from '../../styles/components/layout/Toggle.module.scss'
import Switch from 'react-switch'

type ToggleSwitchProps = {
    isOn: boolean
    handleToggle: () => void
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ isOn, handleToggle }) => {
    return (
        <div className={styles.toggleSwitchContainer}>
            <Switch
                checked={isOn}
                onChange={handleToggle}
                onColor='#DAAB3A'
                offColor='#E9E0D1'
                onHandleColor='#ffffff'
                offHandleColor='#ffffff'
                handleDiameter={30}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
                activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
                height={35}
                width={70}
            />
        </div>
    )
}

export default ToggleSwitch

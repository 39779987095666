import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import CoLivingPropertyAPI from '../services/CoLivingPropertyAPI'
import UserAPI from '../services/UserAPI'
import MediaAPI from '../services/MediaAPI'
import authReducer from '../features/authSlice' // Importez le reducer auth
import notificationReducer from '../features/notificationSlice' // Importez le reducer notif
//import loginReducer from '../features/LoginSlice'
//import registerReducer from '../features/RegisterSlice'

export const GlobalStore = configureStore({
    reducer: {
        CoLivingPropertyAPI: CoLivingPropertyAPI.reducer,
        UserAPI: UserAPI.reducer,
        MediaAPI: MediaAPI.reducer,
        Auth: authReducer,
        Notification: notificationReducer
        //Login: loginReducer,
        //Register: registerReducer
    },

    // - Caching
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            CoLivingPropertyAPI.middleware,
            UserAPI.middleware,
            MediaAPI.middleware
        )
})
setupListeners(GlobalStore.dispatch)

export type RootState = ReturnType<typeof GlobalStore.getState>
export type AppDispatch = typeof GlobalStore.dispatch

export default GlobalStore

import Global from '../styles/pages/Global.module.scss'
/*import PrivacyPolicy from '../styles/pages/PrivacyPolicy.module.scss'*/

const PrivacyPolicy = () => {
    return (
        <div className={Global.Pages}>
            <h2>Politique de confidentialité</h2>
        </div>
    )
}

export default PrivacyPolicy

import Global from '../styles/pages/Global.module.scss'
import styles from '../styles/pages/WelcomeLessor.module.scss'
import LessorImage from '../shared/images/lessor.svg'
import React from 'react'
import { Link } from 'react-router-dom'
import inconChecked from '../shared/images/icon check.svg'
const WelcomeLessor: React.FC = () => {
    return (
        <div className={Global.Pages}>
            <div className={styles.mainPicture}>
                <img src={LessorImage} />
                <div className={styles.borderBackground}></div>
            </div>
            <div className={styles.mainTitle}>
                <h2>Devenir bailleur Co-Spaces</h2>
                <img src={inconChecked} />
                <h2>Merci !</h2>
                <p>
                    Un membre de l&apos;équipe vous contactera très
                    prochainement
                </p>
            </div>
            <div className={styles.RedirectButton}>
                <Link to='/'>
                    <button>Accueil</button>
                </Link>
            </div>
        </div>
    )
}

export default WelcomeLessor

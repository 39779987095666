import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { RootState } from '../../store/GlobalStore'
import style from '../../styles/pages/account/Info.module.scss'
import {
    ModifyLesseeFormData,
    UserUpdateResponse,
    MediaUploadResponse
} from '../../utils/Interface'
import { useUpdateMutation } from '../../services/UserAPI'
import { useUploadMutation } from '../../services/MediaAPI'
import { setUser } from '../../features/authSlice'
import { addNotification } from '../../features/notificationSlice'
import { FormData } from 'formdata-node'
import arrowLeft from '../../shared/images/icon-arrow-left.svg'

const InfoPage: React.FC = () => {
    const authState = useSelector((state: RootState) => state.Auth)
    const { register, handleSubmit, setValue, watch } =
        useForm<ModifyLesseeFormData>()
    const [submitted, setSubmitted] = useState(false)
    const dispatch = useDispatch()
    const [inputContainerClass, setInputContainerClass] = useState(
        style.InpuContainer
    )
    const [modifyUser, { isLoading, isError, isSuccess }] = useUpdateMutation()
    const [uploadMedia] = useUploadMutation()
    const navigate = useNavigate()
    const [image, setImage] = useState<string | null>(null)

    const [firstnameFocused, setFirstnameFocused] = useState(false)
    const [lastnameFocused, setLastnameFocused] = useState(false)
    const [emailFocused, setEmailFocused] = useState(false)
    const [phoneFocused, setPhoneFocused] = useState(false)
    const [birthdateFocused, setBirthdateFocused] = useState(false)
    const firstname = watch('firstname')
    const lastname = watch('lastname')
    const email = watch('email')
    const phone = watch('phone')

    // - UseEffect
    useEffect(() => {
        if (submitted && isError) {
            setInputContainerClass(style.Rejected)
        } else {
            setInputContainerClass('')
        }
    }, [submitted, isError])

    useEffect(() => {
        if (!authState.user) {
            navigate('/se-connecter')
        }
        if (authState.user?.id) setValue('id', authState.user.id)
        if (authState.user?.lastname)
            setValue('lastname', authState.user.lastname)
        if (authState.user?.firstname)
            setValue('firstname', authState.user.firstname)
        if (authState.user?.email) setValue('email', authState.user.email)
        if (authState.user?.phone) setValue('phone', authState.user.phone)
        if (authState.user?.avatar !== null) {
            setImage(
                `${String(process.env.REACT_APP_BASE_URL)}${String(
                    authState.user?.avatar.url
                )}`
            )
            setValue('profilImage', Number(authState.user?.avatar.id))
        }
    }, [authState.user, navigate, setValue, setImage])

    const onSubmit = async (formData: ModifyLesseeFormData) => {
        setSubmitted(true)
        try {
            const result: { data: UserUpdateResponse } = (await modifyUser(
                formData
            )) as { data: UserUpdateResponse }
            console.log(result)
            if (result.data.status == 'success') {
                dispatch(setUser(result.data.user))
                dispatch(
                    addNotification({
                        message:
                            'Vos modifications ont bien été prises en compte.',
                        type: 'success'
                    })
                )
                navigate('/mon-compte') // Rediriger l'utilisateur
            }
        } catch (error) {
            console.error('Failed to update user:', error)
        }
    }

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        const file = e.target.files?.[0]
        if (file) {
            console.log(file)
            const formData = new FormData()
            formData.append('files', file)
            console.log(JSON.stringify(formData))
            const media: { data: MediaUploadResponse[] } = (await uploadMedia(
                formData
            )) as { data: MediaUploadResponse[] }

            setValue('profilImage', Number(media.data[0].id))

            setImage(
                `${String(process.env.REACT_APP_BASE_URL)}${String(
                    media.data[0].url
                )}`
            )
        }
    }

    return (
        <>
            <div className={style.TopContainer}>
                <Link to='/mon-compte'>
                    <button className={style.arrowButton}>
                        <img src={arrowLeft} alt='Arrow Left' />
                    </button>
                </Link>
                <h2>Informations personnelles</h2>
            </div>
            <form
                className={style.ModifyLesseeForm}
                onSubmit={handleSubmit(onSubmit)}>
                <div
                    className={`${style.ModifyLesseeFormChild} ${inputContainerClass}`}>
                    <div className={style.imageUploadContainer}>
                        <label
                            className={style.imageUpload}
                            htmlFor='file-input'>
                            {image ? (
                                <img
                                    src={image}
                                    alt='Uploaded'
                                    className={style.uploadedImg}
                                />
                            ) : (
                                <>
                                    <span className={style.initials}>
                                        {authState.user &&
                                            authState.user.firstname
                                                .substring(0, 1)
                                                .toUpperCase() +
                                                authState.user.lastname
                                                    .substring(0, 1)
                                                    .toUpperCase()}
                                    </span>
                                </>
                            )}
                            <div className={style.plusCircle}>
                                <span className={style.plus}>+</span>
                            </div>
                        </label>
                    </div>
                    <input
                        id='file-input'
                        type='file'
                        className={style.imageInput}
                        onChange={handleFileChange}
                    />
                    <input type='hidden' {...register('id')} />
                    <input
                        type='hidden'
                        {...register('profilImage')} // Enregistrer profilImage
                    />
                    <div className={style.DesktopFormField}>
                        <div
                            className={`${style.InputContainer} ${
                                firstnameFocused || firstname
                                    ? style.Filled
                                    : ''
                            } ${isError && submitted ? style.Rejected : ''}`}>
                            <input
                                type='text'
                                id='firstname'
                                {...register('firstname', { required: true })}
                                required
                                onFocus={() => setFirstnameFocused(true)}
                                onBlur={() => setFirstnameFocused(false)}
                            />
                            <label htmlFor='firstname'>Prénom*</label>
                        </div>
                        <div
                            className={`${style.InputContainer} ${
                                lastnameFocused || lastname ? style.Filled : ''
                            } ${isError && submitted ? style.Rejected : ''}`}>
                            <input
                                type='text'
                                id='lastname'
                                {...register('lastname', { required: true })}
                                required
                                onFocus={() => setLastnameFocused(true)}
                                onBlur={() => setLastnameFocused(false)}
                            />
                            <label htmlFor='lastname'>Nom*</label>
                        </div>
                    </div>
                    <div className={style.DesktopFormField}>
                        <div
                            className={`${style.InputContainer} ${
                                emailFocused || email ? style.Filled : ''
                            } ${isError && submitted ? style.Rejected : ''}`}>
                            <input
                                type='email'
                                id='email'
                                {...register('email', { required: true })}
                                required
                                onFocus={() => setEmailFocused(true)}
                                onBlur={() => setEmailFocused(false)}
                            />
                            <label htmlFor='email'>Mail*</label>
                        </div>
                        <div
                            className={`${style.InputContainer} ${
                                phoneFocused || phone ? style.Filled : ''
                            } ${isError && submitted ? style.Rejected : ''}`}>
                            <input
                                type='text'
                                id='phone'
                                {...register('phone', { required: true })}
                                required
                                onFocus={() => setPhoneFocused(true)}
                                onBlur={() => setPhoneFocused(false)}
                            />
                            <label htmlFor='phone'>Téléphone*</label>
                        </div>
                    </div>
                    <div className={style.DesktopDateField}>
                        <div
                            className={`${style.InputContainer} ${
                                birthdateFocused || authState.user?.birthdate
                                    ? style.Filled
                                    : ''
                            } ${isError && submitted ? style.Rejected : ''}`}>
                            <input
                                type='date'
                                id='birthdate'
                                {...register('birthdate', { required: true })}
                                defaultValue={authState.user?.birthdate}
                                required
                                value=''
                                onFocus={() => setBirthdateFocused(true)}
                                onBlur={() => setBirthdateFocused(false)}
                            />
                            <label htmlFor='birthdate'>
                                Date de naissance*
                            </label>
                        </div>
                    </div>
                </div>
                <div className={style.buttonContainer}>
                    <Link to='/mon-compte'>
                        <button className={style.Cancel}>Annuler</button>
                    </Link>
                    <button
                        type='submit'
                        disabled={isLoading}
                        className={style.Update}>
                        {isLoading ? 'Chargement...' : 'Enregistrer'}
                    </button>
                </div>
                {isError && <p>Error occurred during update.</p>}
                {isSuccess && <p>Updatesuccessful!</p>}
            </form>
        </>
    )
}

export default InfoPage

import Global from '../styles/pages/Global.module.scss'
/*import TermsAndConditions from '../styles/pages/TermsAndConditions.module.scss'*/

const TermsAndConditions = () => {
    return (
        <div className={Global.Pages}>
            <h2>CGV</h2>
        </div>
    )
}

export default TermsAndConditions

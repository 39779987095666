import Global from '../styles/pages/Global.module.scss'
/*import LegalNotices from '../styles/pages/LegalNotices.module.scss'*/

const LegalNotices = () => {
    return (
        <div className={Global.Pages}>
            <h2>Mentions légales</h2>
        </div>
    )
}

export default LegalNotices

/* eslint-disable import/no-extraneous-dependencies */
import Global from '../../styles/pages/Global.module.scss'
import LesseeAccountStyle from '../../styles/components/account/LessorAccount.module.scss'
import React from 'react'
import { Box, Typography, List, ListItem } from '@mui/material'
import { Link } from 'react-router-dom'
import arrow from '../../shared/images/arrow-down.svg'
import user from '../../shared/images/Groupe 3449.svg'
import bell from '../../shared/images/Icon awesome-bell.svg'
import key from '../../shared/images/Icon awesome-key.svg'
import paperclip from '../../shared/images/Icon awesome-paperclip.svg'
import file from '../../shared/images/Icon awesome-file-contract.svg'
import balance from '../../shared/images/Icon awesome-balance-scale.svg'
import card from '../../shared/images/Icon awesome-credit-card.svg'
import time from '../../shared/images/Icon awesome-history.svg'
import envelop from '../../shared/images/Icon zocial-email.svg'
const LessorAccount: React.FC = () => {
    return (
        <div className={Global.Pages}>
            <div className={LesseeAccountStyle.DesktopFormField}>
                <Box className={LesseeAccountStyle.AccountBox}>
                    <Typography className={LesseeAccountStyle.AccountTitle}>
                        GESTION DE VOTRE COMPTE
                    </Typography>
                    <List>
                        <ListItem
                            className={LesseeAccountStyle.Button}
                            button
                            component={Link}
                            to='./mes-informations'>
                            <div className={LesseeAccountStyle.Subtitle}>
                                <img
                                    src={user}
                                    alt='user'
                                    className={LesseeAccountStyle.AccountIcon}
                                />
                                <div>Informations personnelles</div>
                            </div>
                            <img src={arrow} alt='arrow' />
                        </ListItem>
                        <ListItem
                            className={LesseeAccountStyle.MiddleButton}
                            button
                            component={Link}
                            to='./change-password'>
                            <div className={LesseeAccountStyle.Subtitle}>
                                <img
                                    src={key}
                                    alt='key'
                                    className={LesseeAccountStyle.AccountIcon}
                                />
                                <div>Mon mot de passe</div>
                            </div>
                            <img src={arrow} alt='arrow' />
                        </ListItem>
                        <ListItem
                            className={LesseeAccountStyle.Button}
                            button
                            component={Link}
                            to='./notifications'>
                            <div className={LesseeAccountStyle.Subtitle}>
                                <img
                                    src={bell}
                                    alt='bell'
                                    className={LesseeAccountStyle.AccountIcon}
                                />
                                <div>Notifications</div>
                            </div>
                            <img src={arrow} alt='arrow' />
                        </ListItem>
                    </List>
                </Box>
                <Box className={LesseeAccountStyle.AccountBox}>
                    <Typography className={LesseeAccountStyle.AccountTitle}>
                        MES LOCATIONS
                    </Typography>
                    <List>
                        <ListItem
                            className={LesseeAccountStyle.SimpleButton}
                            button
                            component={Link}
                            to='/mon-compte'>
                            <div className={LesseeAccountStyle.Subtitle}>
                                <img
                                    src={paperclip}
                                    alt='paperclip'
                                    className={LesseeAccountStyle.AccountIcon}
                                />
                                <div>Gérer mes annonces</div>
                            </div>
                            <img src={arrow} alt='arrow' />
                        </ListItem>
                        <ListItem
                            className={LesseeAccountStyle.Button}
                            button
                            component={Link}
                            to='/mon-compte'>
                            <div className={LesseeAccountStyle.Subtitle}>
                                <img
                                    src={file}
                                    alt='file'
                                    className={LesseeAccountStyle.AccountIcon}
                                />
                                <div>Créer une annonce de location</div>
                            </div>
                            <img src={arrow} alt='arrow' />
                        </ListItem>
                    </List>
                </Box>
                <Box className={LesseeAccountStyle.AccountBox}>
                    <Typography className={LesseeAccountStyle.AccountTitle}>
                        MES DOCUMENTS
                    </Typography>
                    <List>
                        <ListItem
                            className={LesseeAccountStyle.SimpleButton}
                            button
                            component={Link}
                            to='/mon-compte'>
                            <div className={LesseeAccountStyle.Subtitle}>
                                <img
                                    src={file}
                                    alt='file'
                                    className={LesseeAccountStyle.AccountIcon}
                                />
                                <div>Mon contrat</div>
                            </div>
                            <img src={arrow} alt='arrow' />
                        </ListItem>
                        <ListItem
                            className={LesseeAccountStyle.Button}
                            button
                            component={Link}
                            to='/mon-compte'>
                            <div className={LesseeAccountStyle.Subtitle}>
                                <img
                                    src={balance}
                                    alt='balance'
                                    className={LesseeAccountStyle.AccountIcon}
                                />
                                <div>CGV & CGU</div>
                            </div>
                            <img src={arrow} alt='arrow' />
                        </ListItem>
                    </List>
                </Box>
            </div>
            <div className={LesseeAccountStyle.DesktopFormFieldTwo}>
                <Box className={LesseeAccountStyle.AccountBox}>
                    <Typography className={LesseeAccountStyle.AccountTitle}>
                        MES REVENUS & PERFORMANCES
                    </Typography>
                    <List>
                        <ListItem
                            className={LesseeAccountStyle.SimpleButton}
                            button
                            component={Link}
                            to='/mon-compte'>
                            <div className={LesseeAccountStyle.Subtitle}>
                                <img
                                    src={card}
                                    alt='card'
                                    className={LesseeAccountStyle.AccountIcon}
                                />
                                <div>Mes revenus</div>
                            </div>
                            <img src={arrow} alt='arrow' />
                        </ListItem>
                        <ListItem
                            className={LesseeAccountStyle.Button}
                            button
                            component={Link}
                            to='/mon-compte'>
                            <div className={LesseeAccountStyle.Subtitle}>
                                <img
                                    src={time}
                                    alt='time'
                                    className={LesseeAccountStyle.AccountIcon}
                                />
                                <div>Mes statistiques</div>
                            </div>
                            <img src={arrow} alt='arrow' />
                        </ListItem>
                    </List>
                </Box>
                <Box className={LesseeAccountStyle.AccountBox}>
                    <Typography className={LesseeAccountStyle.AccountTitle}>
                        E-CONCIERGERIE
                    </Typography>
                    <List>
                        <ListItem
                            className={LesseeAccountStyle.Button}
                            button
                            component={Link}
                            to='/mon-compte'>
                            <div className={LesseeAccountStyle.Subtitle}>
                                <img
                                    src={envelop}
                                    alt='envelop'
                                    className={LesseeAccountStyle.AccountIcon}
                                />
                                <div>Messagerie</div>
                            </div>
                            <img src={arrow} alt='arrow' />
                        </ListItem>
                    </List>
                </Box>
            </div>
        </div>
    )
}

export default LessorAccount

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { environment } from '../utils/Environment'
import { CoLivingProperty } from '../utils/Interface'
import qs from 'qs'

const CoLivingPropertyAPI = createApi({
    reducerPath: 'CoLivingPropertyAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: `${environment.api_url as string}co-living-houses`
    }),

    endpoints: (build) => ({
        getAllCoLivingPropertys: build.query<CoLivingProperty, void>({
            query: () => ''
        }),
        getCoLivingPropertyById: build.query<CoLivingProperty, number>({
            query: (id) => `/${id}`
        }),
        getCoLivingPropertysByParams: build.query<
            CoLivingProperty,
            { quantity: number; sortBy: 'asc' | 'desc' }
        >({
            query: ({ quantity, sortBy }) => {
                const queryParams = qs.stringify({
                    'pagination[limit]': quantity,
                    'sort[0]': `createdAt:${sortBy}`,
                    fields: ['Name', 'City', 'Description'],
                    populate: {
                        co_living_rooms: {
                            fields: ['Name', 'Price'],
                            populate: ['Pictures']
                        }
                    },
                    encodeValuesOnly: true
                })
                return `?${queryParams}`
            }
        })
    })
})

export default CoLivingPropertyAPI
export const {
    useGetAllCoLivingPropertysQuery,
    useGetCoLivingPropertyByIdQuery,
    useGetCoLivingPropertysByParamsQuery
} = CoLivingPropertyAPI

import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const useBackgroundColor = () => {
    const location = useLocation()

    useEffect(() => {
        switch (location.pathname) {
            case '/mon-compte':
                document.body.style.backgroundColor = '#F0EFEA' // Gris moyen pour la page de profil
                document.body.style.paddingTop = '85px'
                break
            case '/mon-compte/mes-informations':
                document.body.style.backgroundColor = '#F0EFEA' // Gris moyen pour la page de profil
                document.body.style.paddingTop = '85px'
                break
            case '/mon-compte/change-password':
                document.body.style.backgroundColor = '#F0EFEA' // Gris moyen pour la page de profil
                document.body.style.paddingTop = '85px'
                break
            case '/mon-compte/notifications':
                document.body.style.backgroundColor = '#F0EFEA' // Gris moyen pour la page de profil
                document.body.style.paddingTop = '85px'
                break
            default:
                document.body.style.backgroundColor = '#FFFFFF' // Couleur par défaut
                document.body.style.paddingTop = '85px'
                break
        }
    }, [location.pathname])
}

export default useBackgroundColor

import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
//import { UserData } from '../utils/Interface'
//import Global from '../styles/pages/Global.module.scss'
import Profil from '../../styles/pages/account/Profil.module.scss'
import LessorAccount from '../../components/account/LessorAccount'
import LesseeAccount from '../../components/account/LesseeAccount'
import { RootState } from '../../store/GlobalStore'
import { clearAuthData } from '../../features/authSlice'
import { useNavigate } from 'react-router-dom'
//import { ToastContainer, toast } from 'react-toastify'
import NotificationContainer from '../../components/layout/NotificationContainer'
import 'react-toastify/dist/ReactToastify.css'
//import { addNotification } from '../../features/notificationSlice'

const ProfilPage: React.FC = () => {
    const authState = useSelector((state: RootState) => state.Auth)
    //const notification = useSelector((state: RootState) => state.Notification)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [image, setImage] = useState<string | null>(null)

    const handleLogout = () => {
        dispatch(clearAuthData())
        navigate('/se-connecter')
    }

    useEffect(() => {
        if (!authState.user) {
            navigate('/se-connecter')
        }
        if (authState.user?.avatar !== null) {
            setImage(
                `${String(process.env.REACT_APP_BASE_URL)}${String(
                    authState.user?.avatar.url
                )}`
            )
        }
    }, [authState.user, navigate])

    /*useEffect(() => {
        if (notification.message) {
            dispatch(clearNotification())
        }
    }, [notification, dispatch])*/

    return (
        <div className={Profil.ProfilContainer}>
            <NotificationContainer />
            {authState.user ? (
                <>
                    {image ? (
                        <div className={Profil.AvatarImage}>
                            <img
                                src={image}
                                alt='ProfilImage'
                                className={Profil.uploadedImg}
                            />
                        </div>
                    ) : (
                        <div className={Profil.Avatar}>
                            {authState.user.firstname
                                .substring(0, 1)
                                .toUpperCase() +
                                authState.user.lastname
                                    .substring(0, 1)
                                    .toUpperCase()}
                        </div>
                    )}
                    <h2 className={Profil.Welcome}>
                        Bienvenue {authState.user.firstname}{' '}
                        {authState.user.lastname} !
                    </h2>
                    <button
                        className={Profil.Disconnect}
                        onClick={handleLogout}>
                        Se déconnecter
                    </button>
                    {/* TODO : gérer role avec ENUM*/}
                    {authState.user.roleId == 3 ? (
                        <LesseeAccount />
                    ) : (
                        <LessorAccount />
                    )}
                </>
            ) : (
                <p>Veuillez vous connecter pour accéder à votre profil.</p>
            )}
        </div>
    )
}

export default ProfilPage

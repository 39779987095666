// - IMPORT REACT
import React from 'react'
import { Link } from 'react-router-dom'

// - IMPORT COMPONENTS
import Card from '../components/card/Card'
import TestimonialSlider from '../components/layout/TestimonialSlider'
// - IMPORT API
import { useGetAllCoLivingPropertysQuery } from '../services/CoLivingPropertyAPI'

// - IMPORT INTERFACE
import { CoLivingProperty, CoLivingRoom, Pictures } from '../utils/Interface'

// - IMPORT STYLE
import Global from '../styles/pages/Global.module.scss'
import Home from '../styles/pages/Home.module.scss'
import defaultImage from '../shared/images/default.png'
import Loader from '../components/layout/Loader'
import coworkingImg from '../shared/images/homeCoworking.svg'
import iconSearch from '../shared/images/icon-search-1.svg'
import lightTile from '../shared/images/Groupe 3577.svg'
import darkTile from '../shared/images/Groupe 3578.svg'
const HomePage = () => {
    const {
        data: coLivingPropertyData,
        error: coLivingPropertyError,
        isLoading: coLivingPropertyLoading
    } = useGetAllCoLivingPropertysQuery(/*{
        quantity: 3,
        sortBy: 'asc'
    }*/)

    // - Check
    if (coLivingPropertyError) {
        console.log(coLivingPropertyError)
        console.log(coLivingPropertyData)
        return <div>Error occurred while fetching restaurants.</div>
    }

    // - Wait until the data is loaded
    if (coLivingPropertyLoading) {
        return <Loader />
    }

    // - Parse
    const property = coLivingPropertyData?.data
    console.log(property)
    // - Return array of minimum price
    const findMinimumPrice = (Rooms: CoLivingRoom[]): number | null => {
        if (Rooms && Rooms.length > 0) {
            return Rooms.reduce<number | null>((minPrice, Room) => {
                const roomPrices: number[] = []
                roomPrices.push(Room.attributes.Price)
                const roomsMinPrice = Math.min(...roomPrices)
                return minPrice === null ||
                    (roomsMinPrice !== undefined && roomsMinPrice < minPrice)
                    ? roomsMinPrice
                    : minPrice
            }, null)
        }
        return null
    }

    const featuredImages = property?.map((item: CoLivingProperty) => {
        const picturesData: Pictures[] = item?.attributes?.Pictures?.data ?? []
        if (picturesData.length > 0) {
            console.log(process.env.REACT_APP_BASE_URL)
            if (process.env.REACT_APP_BASE_URL !== undefined) {
                return (
                    process.env.REACT_APP_BASE_URL +
                    picturesData[0].attributes?.url
                )
            }
            return null
        }
        return null
    })

    // - Property map to find the minimum price for each "parent" object.
    const minimumPrices = property?.map((item: CoLivingProperty) => {
        const propertyData = item?.attributes?.co_living_rooms?.data
        if (Array.isArray(property)) {
            return findMinimumPrice(propertyData)
        }
        return null
    })

    // - Returns the number of rooms in a CoLiving property from the CoLivingProperty object
    const getNumberOfRooms = (propertyData: CoLivingProperty): number => {
        return propertyData?.attributes?.co_living_rooms?.data?.length || 0
    }

    // - Returns a list of numbers representing the number of rooms for each property
    const countRoom: number[] =
        property?.map((item: CoLivingProperty) => {
            return getNumberOfRooms(item)
        }) || []

    // - Return
    return (
        <div className={Global.Pages}>
            <div className={Home.TopContainer}>
                <div className={Home.coworkingPicture}>
                    <img src={coworkingImg} />
                    <div className={Home.borderBackground}></div>
                </div>
                <div className={Home.Catchphrase}>
                    <div className={Home.Title}>
                        <h2>co-spaces</h2>
                        <span>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            viverra, quam eu accumsan.
                        </span>
                    </div>
                    <div className={Home.borderBackgroundDesktop}></div>
                </div>
            </div>
            <div className={Home.SearchContainer}>
                <Link className={Home.Link} to='./co-living'>
                    <div className={Home.SearchButton}>
                        <div className={Home.SearchText}>
                            Rechercher
                            <br />
                            <div className={Home.SearchSubText}>
                                Un co-living
                            </div>
                        </div>
                        <img src={iconSearch} alt='iconSearch' />
                    </div>
                </Link>
            </div>
            <h3 className={Home.CardTitle}>
                Nos Co-Living tout nouveaux tout beaux !
            </h3>
            <div className={Home.CardContainer}>
                {Array.isArray(property) &&
                    property.length > 0 &&
                    property.map((item: CoLivingProperty, key) => (
                        <div className={Home.AboveCard} key={key}>
                            <Card
                                {...{
                                    picture: featuredImages?.[key] ?? '',
                                    title: item.attributes?.Name || '',
                                    type: 'Co-living',
                                    price: minimumPrices?.[key] ?? 0,
                                    city: item.attributes?.City || '',
                                    rooms: countRoom?.[key] ?? 0
                                }}
                            />
                        </div>
                    ))}
            </div>

            <div className={Home.RedirectButton}>
                <Link to='./co-living'>
                    <button>Voir tous les co-living</button>
                </Link>
            </div>
            <div className={Home.Register}>
                <div className={Home.middlePicture}>
                    <div>
                        <h2>Envie de faire partie de l&apos;aventure ?</h2>
                    </div>
                </div>
                <div className={Home.AdvantageContainer}>
                    <RegisterPannel
                        {...{
                            title: 'Je souhaite louer ',
                            strongTitle: 'un co-living',
                            description:
                                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla lobortis eros augue, vitae accumsan ante viverra nec.',
                            pictures: [
                                {
                                    src: `${lightTile}`,
                                    alt: '',
                                    text: "Nom de l'avantage"
                                },
                                {
                                    src: `${lightTile}`,
                                    alt: '',
                                    text: "Nom de l'avantage"
                                },
                                {
                                    src: `${lightTile}`,
                                    alt: '',
                                    text: "Nom de l'avantage"
                                }
                            ],
                            button: "M'inscrire pour louer",
                            redirection: './devenir-locataire'
                        }}
                    />
                    <RegisterPannel
                        {...{
                            title: 'Je souhaite ',
                            strongTitle: 'mettre mon bien en location',
                            description:
                                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla lobortis eros augue, vitae accumsan ante viverra nec.',
                            pictures: [
                                {
                                    src: `${darkTile}`,
                                    alt: '',
                                    text: "Nom de l'avantage"
                                },
                                {
                                    src: `${darkTile}`,
                                    alt: '',
                                    text: "Nom de l'avantage"
                                },
                                {
                                    src: `${darkTile}`,
                                    alt: '',
                                    text: "Nom de l'avantage"
                                }
                            ],
                            button: "M'inscrire pour mettre en location",
                            redirection: './louer-mon-bien'
                        }}
                    />
                </div>
            </div>
            <div className={Home.Testimonial}>
                <h3>
                    Ils ont testé <strong>co-spaces</strong>
                </h3>
                <TestimonialSlider />
            </div>
        </div>
    )
}

/* ----------------------------------------------------------
    RegisterPannel only appears on this page,
    so there's no need to create a typescript file for it
-----------------------------------------------------------*/
type Picture = {
    //attributes: any
    src: string
    alt: string
    text: string
}

type RegisterProps = {
    title: string
    strongTitle: string
    description: string
    pictures: Picture[]
    button: string
    redirection: string
}

const RegisterPannel: React.FunctionComponent<RegisterProps> = (props) => {
    const { title, strongTitle, description, pictures, button, redirection } =
        props
    return (
        <div className={Home.RegisterCard}>
            <h3>
                {title}
                <strong>{strongTitle}</strong>
            </h3>
            <p>{description}</p>
            <div className={Home.ImageContainer}>
                {pictures.map((item, index) => (
                    <div key={index} className={Home.ImageItem}>
                        <div>
                            {item.src ? (
                                <img src={item.src} alt={item.alt} />
                            ) : (
                                <img src={defaultImage} alt='Default' />
                            )}
                        </div>
                        <p>{item.text}</p>
                    </div>
                ))}
            </div>
            <Link to={redirection}>
                <button>{button}</button>
            </Link>
        </div>
    )
}

export default HomePage

import Global from '../styles/pages/Global.module.scss'
import BecomeLessorForm from '../components/form/BecomeLessorForm'
import Lessor from '../styles/pages/BecomeLessor.module.scss'
import LessorImage from '../shared/images/lessor.svg'
import { RootState } from '../store/GlobalStore'
import { useSelector } from 'react-redux'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const BecomeLessor: React.FC = () => {
    const authState = useSelector((state: RootState) => state.Auth)
    const navigate = useNavigate()

    useEffect(() => {
        if (authState.user) {
            navigate('/mon-compte')
        }
    }, [authState.user, navigate])
    return (
        <div className={Global.Pages}>
            <div className={Lessor.mainPicture}>
                <img src={LessorImage} />
                <div className={Lessor.borderBackground}></div>
            </div>
            <div className={Lessor.mainTitle}>
                <h2>Devenir bailleur Co-Spaces</h2>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing,
                    <u>avantages à devenir locataire co-spaces</u>
                </p>
            </div>
            <BecomeLessorForm />
        </div>
    )
}

export default BecomeLessor

// TestimonialSlider.js
import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import styles from '../../styles/components/layout/TestimonialSlider.module.scss'
import iconUser from '../../shared/images/testimonialUser.svg'

const testimonials = [
    {
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore',
        name: 'Jim Halpert',
        title: 'Co-spaceur à Ville'
    },
    {
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore',
        name: 'Pam Beesly',
        title: 'Artist at Dunder Mifflin'
    },
    {
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore',
        name: 'Michael Scott',
        title: 'Director at Dunder Mifflin'
    }
    // Add more testimonials as needed
]

const TestimonialSlider: React.FC = () => {
    const settings = {
        dots: false,
        infinite: false,
        arrows: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: false,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024, // Desktop breakpoint
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            }
        ],
        appendDots: (dots) => (
            <div
                style={{
                    position: 'absolute',
                    bottom: '-90px', // Adjust the positioning as needed
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                <ul> {dots} </ul>
            </div>
        )
    }

    return (
        <div className={styles.testimonialSlider}>
            <Slider {...settings}>
                {testimonials.map((testimonial, index) => (
                    <div key={index} className={styles.testimonial}>
                        <blockquote>{`« ${testimonial.text} »`}</blockquote>
                        <div className={styles.author}>
                            <div className={styles.avatar}>
                                <img src={iconUser} alt='iconUser' />
                            </div>
                            <div className={styles.authorInfo}>
                                <h4>{testimonial.name}</h4>
                                <p>{testimonial.title}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    )
}

export default TestimonialSlider

import Global from '../styles/pages/Global.module.scss'
/*import WebsiteMap from '../styles/pages/WebsiteMap.module.scss'*/

const WebsiteMap = () => {
    return (
        <div className={Global.Pages}>
            <h2>Plan du site</h2>
        </div>
    )
}

export default WebsiteMap

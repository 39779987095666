import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { RootState } from '../../store/GlobalStore'
import style from '../../styles/pages/account/Password.module.scss'
import {
    ModifyPasswordFormData,
    UserUpdateResponse
} from '../../utils/Interface'
import { useChangePasswordMutation } from '../../services/UserAPI'
import { setUser } from '../../features/authSlice'
//import { setNotification } from '../../features/notificationSlice'
import eyes from '../../shared/images/Icon eyes.svg'
import arrowLeft from '../../shared/images/icon-arrow-left.svg'
const PasswordPage: React.FC = () => {
    const authState = useSelector((state: RootState) => state.Auth)
    const [type, setType] = useState('password')
    const { register, handleSubmit, setValue } =
        useForm<ModifyPasswordFormData>()
    const [submitted, setSubmitted] = useState(false)
    const [localError, setLocalError] = useState(false)
    const [errorLog, setErrorLog] = useState('')
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [inputContainerClass, setInputContainerClass] = useState(
        style.InpuContainer
    )
    const [modifyUserPassword, { isLoading, isError, isSuccess }] =
        useChangePasswordMutation()

    // - UseEffect
    useEffect(() => {
        console.log(localError)
        if ((submitted && isError) || localError) {
            setInputContainerClass(style.InpuContainer + ' ' + style.Rejected)
        } else {
            setInputContainerClass(style.InpuContainer)
        }
    }, [submitted, isError, localError])

    useEffect(() => {
        if (!authState.user) {
            navigate('/se-connecter')
        }
        if (authState.user?.id) setValue('id', authState.user.id)
    }, [authState.user, navigate, setValue])

    // - Form event
    const handleToggle = () => {
        setType((prevType) => (prevType === 'password' ? 'text' : 'password'))
    }

    const onSubmit = async (formData: ModifyPasswordFormData) => {
        setSubmitted(true)
        if (formData.newPassword !== formData.confirmPassword) {
            setErrorLog('Les nouveaux mots de passe ne sont pas identiques.')
            setLocalError(true)
            return
        } else if (
            formData.newPassword == formData.confirmPassword &&
            formData.newPassword == formData.currentPassword
        ) {
            setErrorLog(
                "Veuillez renseigner un nouveau mot de passe différent de l'actuel"
            )
            setLocalError(true)
            return
        }
        try {
            const result: { data: UserUpdateResponse } =
                (await modifyUserPassword(formData)) as {
                    data: UserUpdateResponse
                }
            console.log(result)
            if (result.data.status == 'success') {
                dispatch(setUser(result.data.user))
                /*dispatch(
                    setNotification({
                        message: 'Mot de passe modifié avec succès !',
                        type: 'success'
                    })
                )*/
                navigate('/mon-compte') // Rediriger l'utilisateur
            }
        } catch (error) {
            console.error('Failed to update user:', error)
            setErrorLog(
                'Une erreur est survenue, merci de contacter le support'
            )
        }
    }

    return (
        <>
            <div className={style.TopContainer}>
                <Link to='/mon-compte'>
                    <button className={style.arrowButton}>
                        <img src={arrowLeft} alt='Arrow Left' />
                    </button>
                </Link>
                <h2>Mon mot de passe</h2>
                <p>Modifier mon mot de passe</p>
                <span className={style.passwordRules}>
                    <p>Pour créer un mot de passe sécurisé :</p>
                    <p>. Utiliser au minimum 8 caractères</p>
                    <p>
                        . Utiliser un mélange de lettres, de chiffres et de
                        caractères spéciaux (ex %*)
                    </p>
                    <p>
                        . Essayer de combiner des motes et des symboles en une
                        phrase unique.
                    </p>
                </span>
            </div>

            <form
                className={style.ChangePasswordForm}
                onSubmit={handleSubmit(onSubmit)}>
                {submitted && (localError || isError) && (
                    <h3 className={style.TitleInputError}>{errorLog}</h3>
                )}
                <input type='hidden' {...register('id')} />
                <div
                    className={`${inputContainerClass} ${style.ActualPassword}`}>
                    <input
                        type={type}
                        placeholder='Mot de passe actuel'
                        {...register('currentPassword', { required: true })}
                    />
                    <img
                        src={eyes}
                        alt='Eyes'
                        className={style.PasswordIcon}
                        onClick={handleToggle}
                    />
                </div>
                <div className={inputContainerClass}>
                    <input
                        type={type}
                        placeholder='Nouveau mot de passe'
                        {...register('newPassword', { required: true })}
                    />
                    <img
                        src={eyes}
                        alt='Eyes'
                        className={style.PasswordIcon}
                        onClick={handleToggle}
                    />
                </div>
                <div className={inputContainerClass}>
                    <input
                        type={type}
                        placeholder='Confirmation du mot de passe'
                        {...register('confirmPassword', { required: true })}
                    />
                    <img
                        src={eyes}
                        alt='Eyes'
                        className={style.PasswordIcon}
                        onClick={handleToggle}
                    />
                </div>
                <div className={style.buttonContainer}>
                    <Link to='/mon-compte'>
                        <button className={style.Cancel}>Annuler</button>
                    </Link>
                    <button
                        type='submit'
                        disabled={isLoading}
                        className={style.Update}>
                        {isLoading ? 'Chargement...' : 'Enregistrer'}
                    </button>
                </div>
                {isError && <p>Error occurred during update.</p>}
                {isSuccess && <p>Updatesuccessful!</p>}
            </form>
        </>
    )
}

export default PasswordPage

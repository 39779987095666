// File: App.tsx
// Purpose: This file contains the app.

// - IMPORT REACT
import { Route, Routes } from 'react-router-dom'

// - IMPORT UTILS
import ScrollToTop from './utils/ScrollToTop'

// - IMPORT STYLE
import './App.scss'

// - IMPORT LAYOUT
import Header from './components/layout/Header'
import Footer from './components/layout/Footer'

// - IMPORT PAGES
import Help from './pages/Help'
import Home from './pages/Home'
import Login from './pages/Login'
import NotFound from './pages/NotFound'
import CoLiving from './pages/CoLiving'
import WebsiteMap from './pages/WebsiteMap'
import BecomeLessee from './pages/BecomeLessee'
import BecomeLessor from './pages/BecomeLessor'
import WelcomeLessor from './pages/WelcomeLessor'
import WelcomeLessee from './pages/WelcomeLessee'
import LegalNotices from './pages/LegalNotices'
import PrivacyPolicy from './pages/PrivacyPolicy'
import ForgotPassword from './pages/ForgotPassword'
import TermsAndConditions from './pages/TermsAndConditions'
import Profil from './pages/account/Profil'
import Info from './pages/account/Info'
import Password from './pages/account/Password'
import Notifications from './pages/account/Notifications'
import useBackgroundColor from './hooks/BackgroundColor'

const App = () => {
    useBackgroundColor() // Applique la couleur de fond en fonction de la route
    return (
        <div className='App'>
            <ScrollToTop />
            <Header />

            {/*Define all pages and corresponding element*/}
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/co-living' element={<CoLiving />} />
                <Route path='/se-connecter' element={<Login />} />
                <Route path='/mon-compte' element={<Profil />} />
                <Route path='/mon-compte/mes-informations' element={<Info />} />
                <Route
                    path='/mon-compte/change-password'
                    element={<Password />}
                />
                <Route
                    path='/mon-compte/notifications'
                    element={<Notifications />}
                />
                <Route path='/devenir-locataire' element={<BecomeLessee />} />
                <Route path='/louer-mon-bien' element={<BecomeLessor />} />
                <Route path='/welcome-lessor' element={<WelcomeLessor />} />
                <Route path='/welcome-lessee' element={<WelcomeLessee />} />
                <Route
                    path='/mot-de-passe-oublie'
                    element={<ForgotPassword />}
                />
                <Route path='/aide' element={<Help />} />
                <Route path='/mentions-legales' element={<LegalNotices />} />
                <Route
                    path='/politique-de-confidentialite'
                    element={<PrivacyPolicy />}
                />
                <Route path='/cgv' element={<TermsAndConditions />} />
                <Route path='/plan-du-site' element={<WebsiteMap />} />
                <Route path='*' element={<NotFound />} />
            </Routes>

            <Footer />
        </div>
    )
}

export default App

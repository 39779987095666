// eslint-disable-next-line import/named
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UserData, User } from '../utils/Interface'
//import { loginAsync } from '../services/UserAPI'

const initialState: UserData = {
    jwt: null,
    user: null
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthData(state, action: PayloadAction<{ jwt: string; user: User }>) {
            state.jwt = action.payload.jwt
            state.user = action.payload.user
        },
        setUser(state, action: PayloadAction<User>) {
            state.user = action.payload
        },
        clearAuthData(state) {
            state.jwt = null
            state.user = null
        }
    }
})

export const { setAuthData, clearAuthData, setUser } = authSlice.actions
export default authSlice.reducer

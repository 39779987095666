// eslint-disable-next-line import/named
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { NotificationState, NotificationType } from '../utils/Interface'

const initialState: NotificationState = {
    notifications: []
}

const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        addNotification: (
            state,
            action: PayloadAction<{ message: string; type: NotificationType }>
        ) => {
            const id = new Date().getTime()
            state.notifications.push({ id, ...action.payload })
        },
        removeNotification: (state, action: PayloadAction<number>) => {
            state.notifications = state.notifications.filter(
                (notification) => notification.id !== action.payload
            )
        }
    }
})

export const { addNotification, removeNotification } = notificationSlice.actions
export default notificationSlice.reducer

import Global from '../styles/pages/Global.module.scss'
import NoAccount from '../components/account/NoAccount'
import ForgotPasswordForm from '../components/form/ForgotPasswordForm'

const ForgotPassword = () => {
    return (
        <div className={Global.Pages}>
            <h2>Mot de passe oublié ?</h2>
            <ForgotPasswordForm />
            <NoAccount />
        </div>
    )
}

export default ForgotPassword

import Global from '../styles/pages/Global.module.scss'
/*import NotFound from '../styles/pages/NotFound.module.scss'*/

const NotFound = () => {
    return (
        <div className={Global.Pages}>
            <h2>Erreur 404</h2>
        </div>
    )
}

export default NotFound

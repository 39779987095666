import Global from '../styles/pages/Global.module.scss'
import styles from '../styles/pages/WelcomeLessee.module.scss'
import LesseeImage from '../shared/images/lessee.svg'
import React from 'react'
import { Link } from 'react-router-dom'
import inconChecked from '../shared/images/icon check.svg'
const WelcomeLessee: React.FC = () => {
    return (
        <div className={Global.Pages}>
            <div className={styles.mainPicture}>
                <img src={LesseeImage} />
                <div className={styles.borderBackground}></div>
            </div>
            <div className={styles.mainTitle}>
                <h2>Devenir locataire Co-Spaces</h2>
                <img src={inconChecked} />
                <h2>Bienvenue !</h2>
                <p>Votre compte a bien été créé</p>
            </div>
            <div className={styles.RedirectButton}>
                <Link to='/mon-compte'>
                    <button>Me connecter</button>
                </Link>
            </div>
        </div>
    )
}

export default WelcomeLessee

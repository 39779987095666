// File: Footer.tsx
// Purpose: This file contains the footer of the site.
import style from '../../styles/components/layout/Footer.module.scss'
import { Link } from 'react-router-dom'
import logo from '../../shared/images/cospaces-logo.svg'
import React from 'react'

const Footer: React.FC = () => {
    return (
        <div className={style.Footer}>
            <div className={style.FooterTop}>
                <div className={style.LogoContainer}>
                    <Link to='./'>
                        <img src={logo} alt='Co-spaces' />
                    </Link>
                    <div className={style.LogoTitle}>co-spaces</div>
                </div>
            </div>
            <div className={style.LinkContainer}>
                <Link to='./co-living'>LES CO-LIVING</Link>
                <Link to=''>DEVENIR LOCATAIRE</Link>
                <Link to=''>LOUER MON BIEN SUR CO-SPACES</Link>
            </div>
            <div className={style.ContactUs}>
                <Link to='./aide'>Nous contacter</Link>
            </div>
            {/*<div className={style.Newsletter}>
                <span>S&apos;inscrire à la newsletter</span>
                <input type='texte' placeholder='Votre email' />
    </div>*/}
            <div className={style.FooterBottom}>
                <div className={style.Informations}>
                    <Link to='./mentions-legales'>Mentions légales</Link>
                    <span>|</span>
                    <Link to='./politique-de-confidentialite'>
                        Politique de confidentialité
                    </Link>
                    <span>|</span>
                    <Link to='./cgv'>CGV</Link>
                    <span>|</span>
                    <Link to='./plan-du-site'>Plan du site</Link>
                </div>
                <div className={style.CopyRight}>
                    <span>&copy; 2024 CO-SPACES tous droits réservés</span>
                </div>
            </div>
        </div>
    )
}

export default Footer

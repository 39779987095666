import Global from '../styles/pages/Global.module.scss'
/*import CoLiving from '../styles/pages/CoLiving.module.scss'*/

const CoLiving = () => {
    return (
        <div className={Global.Pages}>
            <h2>Co-living</h2>
        </div>
    )
}

export default CoLiving

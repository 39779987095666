import Global from '../styles/pages/Global.module.scss'
/*import Help from '../styles/pages/Help.module.scss'*/

const Help = () => {
    return (
        <div className={Global.Pages}>
            <h2>Besoin d&apos;aide ?</h2>
        </div>
    )
}

export default Help

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { environment } from '../utils/Environment'
import { FormData } from 'formdata-node'
import { MediaUploadResponse } from '../utils/Interface'
import { RootState } from '../store/GlobalStore'

const MediaAPI = createApi({
    reducerPath: 'MediaAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: environment.api_url as string,
        prepareHeaders: (headers, { getState }) => {
            //headers.set('Content-Type', 'application/json')
            const token = (getState() as RootState).Auth.jwt
            if (token) {
                headers.set('Authorization', `Bearer ${token}`)
            }
            return headers
        }
    }),
    endpoints: (build) => ({
        upload: build.mutation<MediaUploadResponse[], FormData>({
            query: (data: FormData) => ({
                url: 'upload',
                method: 'POST',
                body: data
            })
        })
    })
})

export default MediaAPI
export const { useUploadMutation } = MediaAPI

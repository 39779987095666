import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { RootState } from '../../store/GlobalStore'
import style from '../../styles/pages/account/Notifications.module.scss'
import {
    ModifyNotificationsFormData,
    UserUpdateResponse
} from '../../utils/Interface'
import { setUser } from '../../features/authSlice'
//import { setNotification } from '../../features/notificationSlice'
import ToggleSwitch from '../../components/layout/ToggleSwitch'
import { useUpdateNotificationsMutation } from '../../services/UserAPI'
import arrowLeft from '../../shared/images/icon-arrow-left.svg'
const NotificationsPage: React.FC = () => {
    const authState = useSelector((state: RootState) => state.Auth)
    const { register, handleSubmit, setValue } =
        useForm<ModifyNotificationsFormData>()
    const [updateNotifications, { isLoading }] =
        useUpdateNotificationsMutation()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [notifications, setNotifications] = useState({
        accountActivity:
            (authState.user?.Notifications?.accountActivity as boolean) ?? true,
        reservationDocuments:
            (authState.user?.Notifications?.reservationDocuments as boolean) ??
            true,
        reminders:
            (authState.user?.Notifications?.reminders as boolean) ?? true,
        eConcierge:
            (authState.user?.Notifications?.eConcierge as boolean) ?? true,
        newsletter:
            (authState.user?.Notifications?.newsletter as boolean) ?? true,
        satisfactionSurvey:
            (authState.user?.Notifications?.satisfactionSurvey as boolean) ??
            true
    })

    useEffect(() => {
        if (!authState.user) {
            navigate('/se-connecter')
        }
        if (authState.user?.id) setValue('id', authState.user.id)
        if (authState.user?.Notifications?.id)
            setValue('notifId', Number(authState.user.Notifications.id))
    }, [authState.user, navigate, setValue])

    useEffect(() => {
        Object.keys(notifications).forEach((key) => {
            setValue(
                key as keyof ModifyNotificationsFormData,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                notifications[key]
            )
        })
    }, [notifications, setValue])

    const onSubmit = async (formData: ModifyNotificationsFormData) => {
        console.log(formData)
        try {
            const result: { data: UserUpdateResponse } =
                (await updateNotifications(formData)) as {
                    data: UserUpdateResponse
                }
            console.log(result)
            if (result.data.status == 'success') {
                dispatch(setUser(result.data.user))
                /*dispatch(
                    setNotification({
                        message: 'Notifications modifié avec succès !',
                        type: 'success'
                    })
                )*/
                navigate('/mon-compte') // Rediriger l'utilisateur
            }
        } catch (error) {
            console.error('Failed to update user:', error)
        }
    }

    const handleToggle = (name: string) => {
        setNotifications((prevState) => ({
            ...prevState,
            [name]: !prevState[name]
        }))
        console.log(notifications)
    }

    return (
        <>
            <div className={style.TopContainer}>
                <Link to='/mon-compte'>
                    <button className={style.arrowButton}>
                        <img src={arrowLeft} alt='Arrow Left' />
                    </button>
                </Link>
                <h2>Notifications</h2>
                <p>
                    Activer ou désactiver la réception de notifications par
                    email
                </p>
            </div>

            <form
                className={style.ChangeNotificationForm}
                onSubmit={handleSubmit(onSubmit)}>
                <input type='hidden' {...register('id')} />
                <div className={style.notificationItem}>
                    <label>
                        Notifications à propos de l’activité de votre compte
                    </label>
                    <ToggleSwitch
                        isOn={notifications.accountActivity}
                        handleToggle={() => handleToggle('accountActivity')}
                        {...register('accountActivity')}
                    />
                </div>
                <div className={style.notificationItem}>
                    <label>
                        Notifications à propos de la réservation et des
                        documents
                    </label>
                    <ToggleSwitch
                        isOn={notifications.reservationDocuments}
                        handleToggle={() =>
                            handleToggle('reservationDocuments')
                        }
                        {...register('reservationDocuments')}
                    />
                </div>
                <div className={style.notificationItem}>
                    <label>Notifications à propos des rappels</label>
                    <ToggleSwitch
                        isOn={notifications.reminders}
                        handleToggle={() => handleToggle('reminders')}
                        {...register('reminders')}
                    />
                </div>
                <div className={style.notificationItem}>
                    <label>
                        Notifications à propos de l’activité de la
                        e-conciergerie
                    </label>
                    <ToggleSwitch
                        isOn={notifications.eConcierge}
                        handleToggle={() => handleToggle('eConcierge')}
                        {...register('eConcierge')}
                    />
                </div>
                <div className={style.notificationItem}>
                    <label>Notifications à propos de la newsletter</label>
                    <ToggleSwitch
                        isOn={notifications.newsletter}
                        handleToggle={() => handleToggle('newsletter')}
                        {...register('newsletter')}
                    />
                </div>
                <div className={style.notificationItem}>
                    <label>
                        Notifications à propos de questionnaire de satisfaction
                    </label>
                    <ToggleSwitch
                        isOn={notifications.satisfactionSurvey}
                        handleToggle={() => handleToggle('satisfactionSurvey')}
                        {...register('satisfactionSurvey')}
                    />
                </div>
                <div className={style.buttonContainer}>
                    <Link to='/mon-compte'>
                        <button className={style.Cancel}>Annuler</button>
                    </Link>
                    <button
                        type='submit'
                        disabled={isLoading}
                        className={style.Update}>
                        {isLoading ? 'Chargement...' : 'Enregistrer'}
                    </button>
                </div>
            </form>
        </>
    )
}

export default NotificationsPage

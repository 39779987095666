import styles from '../../styles/components/layout/Loader.module.scss'

const Loader = () => {
    return (
        <div className={styles.Loader}>
            <div className={styles.loaderContent}>
                <div className={styles.ldsEllipsis}>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    )
}
export default Loader

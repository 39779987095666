import style from '../../styles/components/form/BecomeLesseeForm.module.scss'
import { useForm } from 'react-hook-form'
import { BecomeLesseeFormData, RegisterResponse } from '../../utils/Interface'
import React, { useState, useEffect } from 'react'
import eyes from '../../shared/images/Icon eyes.svg'
import { useRegisterMutation } from '../../services/UserAPI'
import { useNavigate } from 'react-router-dom'

const BecomeLesseeForm: React.FC = () => {
    // Hook form setup
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm<BecomeLesseeFormData>()
    const navigate = useNavigate()
    // - Var
    const [firstnameFocused, setFirstnameFocused] = useState(false)
    const [lastnameFocused, setLastnameFocused] = useState(false)
    const [emailFocused, setEmailFocused] = useState(false)
    const [phoneFocused, setPhoneFocused] = useState(false)
    const [birthdateFocused, setBirthdateFocused] = useState(false)
    const [passwordFocused, setPasswordFocused] = useState(false)
    const [confirmPasswordFocused, setConfirmPasswordFocused] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [type, setType] = useState('password')
    const [errorLog, setErrorLog] = useState('')
    const [localError, setLocalError] = useState(false)
    const [isFirefox, setIsFirefox] = useState(false)
    const [registerUser, { isLoading, isError, isSuccess }] =
        useRegisterMutation()

    const firstname = watch('firstname')
    const lastname = watch('lastname')
    const email = watch('email')
    const phone = watch('phone')
    const birthdate = watch('birthdate')
    const password = watch('password')
    const confirmPassword = watch('confirmpassword')

    useEffect(() => {
        // Detect Firefox browser
        const userAgent = window.navigator.userAgent.toLowerCase()
        setIsFirefox(userAgent.indexOf('firefox') > -1)
    }, [])

    // - Form event
    const handleToggle = () => {
        if (type === 'password') {
            setType('text')
        } else {
            setType('password')
        }
    }

    const isPasswordValid = (passwordString: string) => {
        const minLength = 8
        const letterRegex = /[a-zA-Z]/
        const digitRegex = /[0-9]/
        const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/
        return (
            passwordString.length >= minLength &&
            letterRegex.test(passwordString) &&
            digitRegex.test(passwordString) &&
            specialCharRegex.test(passwordString)
        )
    }

    const onSubmit = async (data: BecomeLesseeFormData) => {
        setErrorLog('')
        setSubmitted(true)
        if (data.password !== data.confirmpassword) {
            setErrorLog('Les mots de passe ne sont pas identiques.')
            setLocalError(true)
            window.scrollTo(0, 0)
            return
        }

        if (!isPasswordValid(data.password)) {
            setErrorLog(
                'Le mot de passe doit contenir au moins 8 caractères, une lettre, un chiffre et un caractère spécial.'
            )
            setLocalError(true)
            window.scrollTo(0, 0)
            return
        }

        try {
            const result: { data: RegisterResponse } = (await registerUser({
                data,
                isLessor: false
            })) as { data: RegisterResponse }
            console.log(result)
            if (result.data.status === 'success') {
                navigate('/welcome-lessee') // Rediriger l'utilisateur
            }
        } catch (error) {
            console.log('Failed to register:', error)
        }
    }

    return (
        <>
            <div className={style.ErrorMsg}>{errorLog}</div>
            {errors.isagreementaccepted && (
                <div className={style.ErrorMsg}>
                    Vous devez accepter les conditions générales
                    d&apos;utilisation et de ventes.
                </div>
            )}
            <form
                className={style.BecomeLesseeForm}
                onSubmit={handleSubmit(onSubmit)}>
                <div className={style.DesktopFormField}>
                    <div
                        className={`${style.InputContainer} ${
                            firstnameFocused || firstname ? style.Filled : ''
                        } ${isError || localError ? style.Rejected : ''}`}>
                        <input
                            type='text'
                            id='firstname'
                            {...register('firstname', { required: true })}
                            required
                            onFocus={() => setFirstnameFocused(true)}
                            onBlur={() => setFirstnameFocused(false)}
                        />
                        <label htmlFor='firstname'>Prénom*</label>
                    </div>
                    <div
                        className={`${style.InputContainer} ${
                            lastnameFocused || lastname ? style.Filled : ''
                        } ${
                            (isError || localError) && submitted
                                ? style.Rejected
                                : ''
                        }`}>
                        <input
                            type='text'
                            id='lastname'
                            {...register('lastname', { required: true })}
                            required
                            onFocus={() => setLastnameFocused(true)}
                            onBlur={() => setLastnameFocused(false)}
                        />
                        <label htmlFor='lastname'>Nom*</label>
                    </div>
                </div>
                <div className={style.DesktopFormField}>
                    <div
                        className={`${style.InputContainer} ${
                            emailFocused || email ? style.Filled : ''
                        } ${
                            (isError || localError) && submitted
                                ? style.Rejected
                                : ''
                        }`}>
                        <input
                            type='email'
                            id='email'
                            {...register('email', { required: true })}
                            required
                            onFocus={() => setEmailFocused(true)}
                            onBlur={() => setEmailFocused(false)}
                        />
                        <label htmlFor='email'>Mail*</label>
                    </div>
                    <div
                        className={`${style.InputContainer} ${
                            phoneFocused || phone ? style.Filled : ''
                        } ${
                            (isError || localError) && submitted
                                ? style.Rejected
                                : ''
                        }`}>
                        <input
                            type='text'
                            id='phone'
                            {...register('phone', { required: true })}
                            required
                            onFocus={() => setPhoneFocused(true)}
                            onBlur={() => setPhoneFocused(false)}
                        />
                        <label htmlFor='phone'>Téléphone*</label>
                    </div>
                </div>
                <div className={style.SingleDesktopFormField}>
                    <div
                        className={`${style.InputContainer} ${
                            birthdateFocused || birthdate || isFirefox
                                ? style.Filled
                                : ''
                        } ${
                            (isError || localError) && submitted
                                ? style.Rejected
                                : ''
                        }`}>
                        <input
                            type='date'
                            id='birthdate'
                            {...register('birthdate', { required: true })}
                            required
                            onFocus={() => setBirthdateFocused(true)}
                            onBlur={() => setBirthdateFocused(false)}
                        />
                        <label htmlFor='birthdate'>
                            Date de naissance (jj/mm/aaaa)*
                        </label>
                    </div>
                </div>
                <div className={style.DesktopFormField}>
                    <div
                        className={`${style.InputContainer} ${
                            passwordFocused || password ? style.Filled : ''
                        } ${
                            (isError || localError) && submitted
                                ? style.Rejected
                                : ''
                        }`}>
                        <input
                            type={type}
                            id='password'
                            {...register('password', { required: true })}
                            required
                            onFocus={() => setPasswordFocused(true)}
                            onBlur={() => setPasswordFocused(false)}
                        />
                        <label htmlFor='password'>Mot de passe*</label>
                        <img
                            src={eyes}
                            alt='Eyes'
                            className={style.PasswordIcon}
                            onClick={handleToggle}
                        />
                    </div>
                    <div
                        className={`${style.InputContainer} ${
                            confirmPasswordFocused || confirmPassword
                                ? style.Filled
                                : ''
                        } ${
                            (isError || localError) && submitted
                                ? style.Rejected
                                : ''
                        }`}>
                        <input
                            type={type}
                            id='confirmpassword'
                            {...register('confirmpassword', { required: true })}
                            required
                            onFocus={() => setConfirmPasswordFocused(true)}
                            onBlur={() => setConfirmPasswordFocused(false)}
                        />
                        <label htmlFor='confirmpassword'>
                            Confirmation du mot de passe*
                        </label>
                        <img
                            src={eyes}
                            alt='Eyes'
                            className={style.PasswordIcon}
                            onClick={handleToggle}
                        />
                    </div>
                </div>
                <div className={style.SingleDesktopFormField}>
                    <label>
                        <input
                            type='checkbox'
                            id='cgu'
                            {...register('isagreementaccepted', {
                                required: true
                            })}
                        />
                        J&apos;accepte que mes donnnées soient traitées pour
                        créer ma candidature et j&apos;accepte les conditions
                        générales d&apos;utilisation et de ventes.
                    </label>
                </div>
                <div className={style.SingleDesktopFormField}>
                    <label>
                        <input
                            type='checkbox'
                            id='newsletter'
                            {...register('isnewsletteraccepted', {
                                required: false
                            })}
                        />
                        J&apos;accepte de recevoir la newsletter de co-spaces.
                    </label>
                </div>
                <div className={style.buttonContainer}>
                    <button type='submit' disabled={isLoading}>
                        {isLoading
                            ? 'Chargement...'
                            : 'Créer mon compte locataire'}
                    </button>
                </div>
                {isError && <p>Error occurred during registration.</p>}
                {isSuccess && <p>Registration successful!</p>}
            </form>
        </>
    )
}

export default BecomeLesseeForm

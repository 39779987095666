import styles from '../../styles/components/account/NoAccount.module.scss'
import Redirect from '../../shared/images/bouton rond.svg'
import { Link } from 'react-router-dom'

const NoAccount = () => {
    return (
        <div className={styles.NoAccount}>
            <h2>Pas encore de compte ? Bienvenue !</h2>
            <div className={styles.BecomeLessee}>
                <Link to='../devenir-locataire'>
                    <div>
                        <span>
                            Je veux <strong>devenir locataire</strong>
                        </span>
                    </div>
                </Link>
                <Link to='../devenir-locataire'>
                    <img src={Redirect} alt='Redirect' />
                </Link>
            </div>
            <div className={styles.RentProperty}>
                <Link to='../louer-mon-bien'>
                    <div>
                        <span>
                            Je veux <strong>mettre un bien en location</strong>
                        </span>
                    </div>
                </Link>
                <Link to='../louer-mon-bien'>
                    <img src={Redirect} alt='Redirect' />
                </Link>
            </div>
        </div>
    )
}

export default NoAccount

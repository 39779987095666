// Notification.tsx
import React, { useEffect } from 'react'
import styles from '../../styles/components/layout/Notification.module.scss'
import iconSuccess from '../../shared/images/icon check.svg'
import iconExit from '../../shared/images/icon exit.svg'
interface NotificationProps {
    message: string
    type: 'success' | 'warning'
    onClose: () => void
}

const NotificationMessage: React.FC<NotificationProps> = ({
    message,
    type,
    onClose
}) => {
    useEffect(() => {
        const timer = setTimeout(onClose, 50000000) // Automatically close after 5 seconds
        return () => clearTimeout(timer)
    }, [onClose])

    return (
        <div className={`${styles.notification} ${type}`}>
            <img src={iconSuccess} alt='check' />
            <span className={styles.notificationMessage}>{message}</span>
            <button className={styles.notificationClose} onClick={onClose}>
                <img src={iconExit} alt='exit' />
            </button>
        </div>
    )
}

export default NotificationMessage

import style from '../../styles/components/form/BecomeLessorForm.module.scss'
import { useForm } from 'react-hook-form'
import { BecomeLessorFormData } from '../../utils/Interface'
import React, { useState } from 'react'
import { useSendLessorDemandMutation } from '../../services/UserAPI'
import { useNavigate } from 'react-router-dom'
const BecomeLessorForm: React.FC = () => {
    // Hook form setup
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm<BecomeLessorFormData>()

    // - State
    const [submitted, setSubmitted] = useState(false)
    const [firstnameFocused, setFirstnameFocused] = useState(false)
    const [lastnameFocused, setLastnameFocused] = useState(false)
    const [emailFocused, setEmailFocused] = useState(false)
    const [phoneFocused, setPhoneFocused] = useState(false)
    const navigate = useNavigate()
    // useSendLessorDemandMutation hook
    const [sendLessorDemand, { isLoading, isError, isSuccess }] =
        useSendLessorDemandMutation()

    const firstname = watch('firstname')
    const lastname = watch('lastname')
    const email = watch('email')
    const phone = watch('phone')

    // - Form event
    const onSubmit = async (data: BecomeLessorFormData) => {
        setSubmitted(true)
        try {
            const result = await sendLessorDemand(data)
            console.log('Lessor demand successful:', result)
            navigate('/welcome-lessor')
        } catch (error) {
            console.error('Failed to send lessor demand:', error)
        }
    }

    return (
        <>
            {errors.isagreementaccepted && (
                <div className={style.ErrorMsg}>
                    Vous devez accepter les conditions générales
                    d&apos;utilisation et de ventes.
                </div>
            )}
            <form
                className={style.BecomeLessorForm}
                onSubmit={handleSubmit(onSubmit)}>
                <div className={style.DesktopFormField}>
                    <div
                        className={`${style.InputContainer} ${
                            firstnameFocused || firstname ? style.Filled : ''
                        } ${isError ? style.Rejected : ''}`}>
                        <input
                            type='text'
                            id='firstname'
                            {...register('firstname', { required: true })}
                            required
                            onFocus={() => setFirstnameFocused(true)}
                            onBlur={() => setFirstnameFocused(false)}
                        />
                        <label htmlFor='firstname'>Prénom*</label>
                    </div>
                    <div
                        className={`${style.InputContainer} ${
                            lastnameFocused || lastname ? style.Filled : ''
                        } ${isError ? style.Rejected : ''}`}>
                        <input
                            type='text'
                            id='lastname'
                            {...register('lastname', { required: true })}
                            required
                            onFocus={() => setLastnameFocused(true)}
                            onBlur={() => setLastnameFocused(false)}
                        />
                        <label htmlFor='lastname'>Nom*</label>
                    </div>
                </div>
                <div className={style.SingleDesktopFormField}>
                    <div
                        className={`${style.InputContainer} ${
                            emailFocused || email ? style.Filled : ''
                        } ${isError ? style.Rejected : ''}`}>
                        <input
                            type='email'
                            id='email'
                            {...register('email', { required: true })}
                            required
                            onFocus={() => setEmailFocused(true)}
                            onBlur={() => setEmailFocused(false)}
                        />
                        <label htmlFor='email'>Mail*</label>
                    </div>
                </div>
                <div className={style.SingleDesktopFormField}>
                    <div className={style.PhoneConact}>
                        Un membre de l&apos;équipe vous contactera gratuitement
                        par téléphone afin de finaliser votre inscription.
                    </div>
                </div>
                <div className={style.SingleDesktopFormField}>
                    <div
                        className={`${style.InputContainer} ${
                            phoneFocused || phone ? style.Filled : ''
                        } ${isError ? style.Rejected : ''}`}>
                        <input
                            type='text'
                            id='phone'
                            {...register('phone', { required: true })}
                            required
                            onFocus={() => setPhoneFocused(true)}
                            onBlur={() => setPhoneFocused(false)}
                        />
                        <label htmlFor='phone'>Téléphone*</label>
                    </div>
                </div>
                <div className={style.SingleDesktopFormField}>
                    <label>
                        <input
                            type='checkbox'
                            id='cgu'
                            {...register('isagreementaccepted', {
                                required: true
                            })}
                        />
                        J&apos;accepte que mes donnnées soient traitées pour
                        créer candidature et j&apos;accepte les conditions
                        générales d&apos;utilisation et de ventes.
                    </label>
                </div>
                <div className={style.SingleDesktopFormField}>
                    <label>
                        <input
                            type='checkbox'
                            id='newsletter'
                            {...register('isnewsletteraccepted', {
                                required: false
                            })}
                        />
                        J&apos;accepte de recevoir la newsletter de co-spaces.
                    </label>
                </div>
                <div className={style.buttonContainer}>
                    <button type='submit' disabled={isLoading}>
                        {isLoading
                            ? 'Chargement...'
                            : 'Je souhaite être contacté'}
                    </button>
                </div>
                {submitted && isSuccess && <div>Demande acceptée!</div>}
            </form>
        </>
    )
}

export default BecomeLessorForm

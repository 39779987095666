// NotificationContainer.tsx
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import NotificationMessage from './NotificationMessage'
import styles from '../../styles/components/layout/Notification.module.scss'
import { removeNotification } from '../../features/notificationSlice'
import { RootState } from '../../store/GlobalStore'
import { Notification, NotificationState } from '../../utils/Interface'

const NotificationContainer: React.FC = () => {
    const notifications: NotificationState = useSelector(
        (state: RootState) => state.Notification
    )
    const dispatch = useDispatch()

    return (
        <div className={styles.notificationContainer}>
            {notifications.notifications.map((notification: Notification) => (
                <NotificationMessage
                    key={notification.id}
                    message={notification.message}
                    type={notification.type}
                    onClose={() =>
                        dispatch(removeNotification(notification.id))
                    }
                />
            ))}
        </div>
    )
}

export default NotificationContainer

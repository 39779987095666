import React from 'react'
import styles from '../../styles/components/card/Card.module.scss'
import defaultImage from '../../shared/images/default.png'

type CardsProps = {
    picture: string
    title: string
    type: string
    price: number
    city: string
    rooms: number
}

const Card: React.FunctionComponent<CardsProps> = (props) => {
    const { picture, title, type, price, city, rooms } = props
    return (
        <div className={styles.Card}>
            <div className={styles.CardTop}>
                <div>
                    {picture ? (
                        <img src={picture} alt={title} />
                    ) : (
                        <img src={defaultImage} alt='Default' />
                    )}
                </div>
            </div>

            <div className={styles.CardMiddle}>
                <h3>{title}</h3>
                <span>
                    {rooms} {rooms <= 1 ? 'Chambre' : 'Chambres'}
                </span>

                {/* TODO : Keep this part of the code as it will be useful in the "CO-LIVING room sheet" page. */}
                {/*{qttVailability === 0 ? (
                    <div>
                        <span className={`${styles.Dot} ${styles.Red}`}></span>
                        <span>Indisponible</span>
                    </div>
                ) : qttVailability === 1 ? (
                    <div>
                        <span
                            className={`${styles.Dot} ${styles.Orange}`}></span>
                        <span>{qttVailability} chambre bientôt disponible</span>
                    </div>
                ) : (
                    <div>
                        <span
                            className={`${styles.Dot} ${styles.Green}`}></span>
                        <span>{qttVailability} chambres disponibles</span>
                    </div>
                )}*/}
            </div>

            <div className={styles.CardBottom}>
                <span>{city}</span>
                <span>
                    <span>À partir de </span>
                    {price}€/Mois
                </span>
            </div>
            <span className={styles.CardType}>{type}</span>
        </div>
    )
}

export default Card

import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { LogingFormData, RegisterResponse } from '../../utils/Interface'
import eyes from '../../shared/images/Icon eyes.svg'
import style from '../../styles/components/form/LoginForm.module.scss'
import { Link, useNavigate } from 'react-router-dom'
import { useLoginMutation } from '../../services/UserAPI'
import { setAuthData } from '../../features/authSlice'

const LoginForm: React.FC = () => {
    // Hook form setup
    const { register, handleSubmit, getValues } = useForm<LogingFormData>()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    // - State
    const [submitted, setSubmitted] = useState(false)
    const [type, setType] = useState('password')
    const [inputContainerClass, setInputContainerClass] = useState(
        style.InputContainer
    )
    const [emailFocused, setEmailFocused] = useState(false)
    const [passwordFocused, setPasswordFocused] = useState(false)

    // useLoginMutation hook
    const [loginUser, { isLoading, isError, isSuccess }] = useLoginMutation()

    // - UseEffect
    useEffect(() => {
        if (submitted && isError) {
            setInputContainerClass(style.InputContainer + ' ' + style.Rejected)
        } else {
            setInputContainerClass(style.InputContainer)
        }
    }, [submitted, isError])

    // - Form event
    const handleToggle = () => {
        setType((prevType) => (prevType === 'password' ? 'text' : 'password'))
    }

    const onSubmit = async (formData: LogingFormData) => {
        setSubmitted(true)
        try {
            const result: { data: RegisterResponse } = (await loginUser(
                formData
            )) as { data: RegisterResponse }
            console.log('Login successful:', result)
            if (result.data.status == 'success') {
                dispatch(
                    setAuthData({
                        jwt: result.data.jwt,
                        user: result.data.user
                    })
                )
                console.log('user : ', result.data.user)
                console.log('Connected')
                navigate('/mon-compte') // Rediriger l'utilisateur
            }
            // TODO: Handle successful login (e.g., redirect, store user info)
        } catch (error) {
            console.error('Failed to login:', error)
        }
    }

    return (
        <>
            {/* If a different visual or a popup is requested depending on the state of the form, it can be handled as follows */}
            {/*{submitted && loginState === 'pending' && <div>Pending...</div>}
            {submitted && loginState === 'fulfilled' && <div>Login successful!</div>}*/}

            <form onSubmit={handleSubmit(onSubmit)} className={style.LoginForm}>
                {submitted && isError && (
                    <h3 className={style.TitleInputError}>
                        Votre email et/ou mot de passe sont invalides. Merci de
                        réessayer
                    </h3>
                )}
                <div
                    className={`${inputContainerClass} ${
                        emailFocused || getValues('email') ? style.Filled : ''
                    }`}>
                    <input
                        type='email'
                        id='email'
                        {...register('email', { required: true })}
                        required
                        onFocus={() => setEmailFocused(true)}
                        onBlur={() => setEmailFocused(false)}
                    />
                    <label htmlFor='email'>Votre email</label>
                </div>
                <div
                    className={`${inputContainerClass} ${
                        passwordFocused || getValues('password')
                            ? style.Filled
                            : ''
                    }`}>
                    <input
                        type={type}
                        id='password'
                        {...register('password', { required: true })}
                        required
                        onFocus={() => setPasswordFocused(true)}
                        onBlur={() => setPasswordFocused(false)}
                    />
                    <label htmlFor='password'>Votre mot de passe</label>
                    <img
                        src={eyes}
                        alt='Eyes'
                        className={style.PasswordIcon}
                        onClick={handleToggle}
                    />
                </div>
                <Link to='../mot-de-passe-oublie'>Mot de passe oublié ?</Link>

                <button type='submit' disabled={isLoading}>
                    {isLoading ? 'Chargement...' : 'Me connecter'}
                </button>
                {submitted && isSuccess && <div>Login successful!</div>}
            </form>
        </>
    )
}

export default LoginForm

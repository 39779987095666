// File: Header.tsx
// Purpose: This file contains the header of the site.
import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/GlobalStore'
import styles from '../../styles/components/layout/Header.module.scss'
import { Link } from 'react-router-dom'
//import Burger from './burger/Burger'
//import Sidemenu from './burger/Sidemenu'
import iconLogin from '../../shared/images/Icon awesome-user-circle.svg'
import iconSearch from '../../shared/images/icon-search.svg'
import iconSearch2 from '../../shared/images/icon-search-1.svg'
import logo from '../../shared/images/cospaces-logo.svg'

const Header: React.FC = () => {
    const authState = useSelector((state: RootState) => state.Auth)

    return (
        <div className={styles.Header}>
            <div className={styles.Left}>
                <div className={styles.Logo}>
                    <Link to='./'>
                        <img src={logo} alt='Co-spaces' />
                    </Link>
                </div>
                <Link to='./'>
                    <div className={styles.Title}>co-spaces</div>
                </Link>
            </div>
            <div className={styles.Right}>
                <div className={styles.LinksContainer}>
                    <div className={styles.SearchLinksContainer}>
                        <Link to='./co-living'>
                            <img
                                className={styles.iconSearch}
                                src={iconSearch}
                                alt='iconSearch'
                            />
                        </Link>
                        <div className={styles.SearchContainer}>
                            <Link className={styles.Link} to='./co-living'>
                                <div className={styles.SearchButton}>
                                    <div className={styles.SearchText}>
                                        Rechercher
                                        <br />
                                        <div className={styles.SearchSubText}>
                                            Un co-living
                                        </div>
                                    </div>
                                    <img src={iconSearch2} alt='iconSearch' />
                                </div>
                            </Link>
                        </div>
                    </div>
                    <Link to='./se-connecter'>
                        {authState.user ? (
                            authState.user.avatar == null ? (
                                <div className={styles.Avatar}>
                                    {authState.user.firstname
                                        .substring(0, 1)
                                        .toUpperCase() +
                                        authState.user.lastname
                                            .substring(0, 1)
                                            .toUpperCase()}
                                </div>
                            ) : (
                                <div className={styles.AvatarImage}>
                                    <img
                                        src={`${String(
                                            process.env.REACT_APP_BASE_URL
                                        )}${String(
                                            authState.user?.avatar.url
                                        )}`}
                                        alt='ProfilImage'
                                        className={styles.uploadedImg}
                                    />
                                </div>
                            )
                        ) : (
                            <img src={iconLogin} alt='iconLogin' />
                        )}
                    </Link>
                </div>
                {/*<Burger open={open} setOpen={setOpen} />*/}
            </div>
            {/*<Sidemenu open={open} setOpen={setOpen} />*/}
        </div>
    )
}

export default Header

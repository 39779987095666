import React, { useEffect } from 'react'
import Global from '../styles/pages/Global.module.scss'
import Login from '../styles/pages/Login.module.scss'
import LoginForm from '../components/form/LoginForm'
import NoAccount from '../components/account/NoAccount'
import puzzle from '../shared/images/puzzle.svg'
import { RootState } from '../store/GlobalStore'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

const LoginPage: React.FC = () => {
    const navigate = useNavigate()
    const authState = useSelector((state: RootState) => state.Auth)
    useEffect(() => {
        if (authState.user) {
            navigate('/mon-compte')
        }
    }, [authState.user, navigate])

    return (
        <div className={Global.Pages}>
            <div className={Login.mainPicture}>
                <img src={puzzle} />
                <div className={Login.borderBackground}></div>
            </div>
            <div className={Login.mainTitle}>
                <h2>Me connecter à mon espace</h2>
            </div>
            <LoginForm />
            <NoAccount />
        </div>
    )
}

export default LoginPage
